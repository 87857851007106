import axios from "axios";
import { pickBy } from "lodash";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { public: config } = useRuntimeConfig();

  const nuxtApp = useNuxtApp();
  const store = nuxtApp.$store;
  const route = useRoute();

  // Session already exists in store
  if (store?.state.provider?.token && store?.state.provider?.product) return;

  try {
    const provider = config.hlg_api_provider;
    if (provider === undefined || provider == "none")
      throw new Error("No provider specified in environment; set HLG_API_PROVIDER in .env before launching HLG");

    let launchData: LaunchResult | undefined;

    try {
      const ldStr = sessionStorage.getItem("HLG_LAUNCHDATA");
      if (ldStr) launchData = JSON.parse(ldStr);

      const homeStr = sessionStorage.getItem("HLG_HOMEURL");
      if (homeStr) store.commit("home/setHomeURL", JSON.parse(homeStr));
    } catch (err) {
      console.error("Unable to read Launch Data from sessionStorage");
      console.error(err);
    }

    if (launchData == undefined || launchData.token == undefined)
      throw new Error("launchData not available, unable to restore session.");

    if (!launchData.preview) {
      // Already have launch data, but restore session to refresh balance and check expiry.
      let { data: res } = await axios.get<LaunchResult>(`${config.bgql}/hlg/session`, {
        params: {
          token: launchData.token,
        },
      });

      if (res.errorMsg) throw new Error(res.errorMsg);

      launchData = { ...launchData, ...pickBy(res, (v) => v !== undefined) };
    }

    await store.dispatch("provider/applyLaunchData", launchData);
    store.commit("provider/setLaunchComplete");

    const productPath: string = store.getters["provider/productPath"];
    if (!route.fullPath.startsWith(productPath)) {
      console.warn("Route differs from session launch data; redirecting.");
      return navigateTo(productPath);
    }
  } catch (err) {
    console.error(err);
    throw createError({ statusCode: 500, message: "error.gameLaunchFailed", fatal: true, unhandled: false });
  }
});
